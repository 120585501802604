.Dashboard{
    /* border: 2px solid red; */
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    background-color: white;
}
.name{
    /* border: 2px solid blue; */
    padding-top:10px;
    background-color: #202020;
    padding-bottom: 10px;
}

.name .logoutlink{
    /* border: 1px solid blue; */
    background-color: #1848a0;
    border-radius: 8px;
    padding: 2px 7px;
    padding-bottom: 5px;
    float: right;
    font-size: 17px;
    font-weight: lighter;
    color: white;
    margin-right: 3rem;
    margin-top: 10px;
}
.name span{
    font-weight:100;
    margin-left:3rem;
    color: white;
}
.dash-body{
    /* border: 2px solid red; */
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    padding-bottom: 4rem;
}
.dash-body .dash-cont-one{
    /* border: 2px solid blue; */
    margin-left:3rem;
}
.dash-cont-one form{
    /* border: 2px solid black; */
    
}
.dash-cont-one form input[type="text"]{
    height: 2rem;
    border-radius: 4px;
    font-size: 15px;
}
.dash-cont-one form .linkname{
    width: 10rem;
}
.dash-cont-one form input[type="submit"]{
    font-size: 15px;
    padding: 7px 12px;
    border-radius: 5px;
    font-weight: 100;
    color: white;
    background-color: #1848a0;
    margin-left: 2px;
}
::placeholder{
    color: black;
}
.dash-cont-one .allLinks{
    /* border: 2px solid green; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 2rem;
}
.dash-cont-one .allLinks .link{
    /* border: 2px solid blue; */
    background-color: #d9d9d9;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    width: 40vw;
    padding: 8px 8px;
}
.link h3{
    font-size: 15px;
    padding: 4px 0;
}
.link p{
    font-size: 15px;
    word-wrap: normal;
    word-break: break-all;
    max-width: 60ch;
}
.link button{
    cursor: pointer;
    width: fit-content;
}
.dash-cont-two{
    /* border: 2px solid yellow; */
    border-radius: 15px;
    width: fit-content;
    margin: auto;
    padding: 4rem 5rem;
    text-align: center;
    background-color: #1848a0;
    color: white;
    font-weight: 100;
    margin-top: 7rem;
}
.dash-cont-two h1{
    font-size: 20px;
    font-weight: 200;
}
.dash-cont-two p{
    word-wrap: normal;
    word-break: break-all;
    max-width: 30ch;
    font-weight: 600;
    padding-top: 5px;
}
@media (max-width: 920px){
    .name span{
        font-size: 30px;
        margin-left: 15px;
    }
    .name .logoutlink{
        margin-right: 15px;
        margin-top: 12px;
        font-size: 17px;
    }
    .dash-body{
        margin: auto;
        flex-direction: column-reverse;
        justify-content: center;
    }
    .dash-cont-two{
        margin: auto;
        margin-top: 3rem;
        padding: 3rem 3rem;
        font-size: 20px;
        margin-right: 2rem;
        margin-left: 2rem;
    }
    .dash-body .dash-cont-one{
        /* margin-left: 0; */
        margin: auto;
        padding: 0 2rem;
        margin-top: 2rem;
    }
    .dash-cont-one .allLinks .link{
        width: 70vw;
        margin: auto;
    }


}
