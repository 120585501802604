a{
    text-decoration: none;
}
.userLinks{
    /* background-color: #5e17eb; */
    background-color: #202020;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
}
.userLinks .centered-div{
    width: fit-content;
    height: fit-content;
    /* border: 2px solid red; */
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* justify-content: center; */
    text-align: center;
}
.userLinks h1{
    /* border: 2px solid red; */
    font-style: italic;
    font-size: 40px;
}
.userLinks .user-name{
    /* border: 2px solid red; */
    margin: auto;
    font-size: 80px;
    text-transform: uppercase;
    font-weight: 100;
    max-width: 99%;
    word-wrap: no wrap;
    overflow: hidden;
    animation:typewriter 8s infinite;

}

@keyframes typewriter{
    0%{
        width: 0;
    }
   
    50%{
        width: 100%;
    }
    100%{
        width: 0%;
    }
  }
  .userLinks .linkslist{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 2rem;
    margin-bottom: 5rem;
}
.userLinks .link-div{
    display: flex;
    /* border: 2px solid red; */
    border-radius: 38px;
    display: flex;
    justify-content: start;
    width: 12rem;
    padding: 6px 26px;
    font-weight: bold;
    color: black;
}
.link-div:nth-child(1){
    background-color: #5271ff;
}
.link-div:nth-child(2){
    background-color: #ff66c4;
}
.link-div:nth-child(3){
    background-color: #5271ff;
}
.link-div:nth-child(4){
    background-color: #ff66c4;
}
.link-div:nth-child(5){
    background-color: #5271ff;
}
.userLinks .link-div p{
    margin: auto;
    font-size: 16px;
    font-weight: bold;
    
}
.userLinks .material-symbols-outlined{
    transform: rotate(-30deg);
}
.site-link{
    position: absolute;
    bottom: 17px;
    left:15px;
    color: white;
    /* text-decoration:underline; */
}
.site-link img{
    height: 20px;
}


@media (max-width: 920px){
    .userLinks{
        overflow-y: scroll;
    }
    .userLinks .user-name{
        font-size: 70px;
        /* white-space: normal; */
        /* word-break:break-all; */
        max-width: 100%;
    }
    .userLinks .centered-div{
        width: 100vw;
        margin: auto;
        /* overflow: scroll; */
    }
    
}