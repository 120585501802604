*{
  
    margin:0;
    -ms-overflow-style:none;
    scrollbar-width: none;
    overflow-y: scroll;
    outline: none;
  }

  ::-webkit-scrollbar{
    display: none;
  }
  .Home{
    overflow: scroll;
  }
  header{
    position: absolute;
    /* border:  2px solid black; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; 
    margin:auto;
    height: fit-content;
    width: 82vw;
    margin-top: 2.5rem;
    border-radius: 10000px;
    background-color: rgb(255, 255, 255);
    padding: 8px 35px;
    display: flex;
    justify-content: space-between;
    position: fixed;
  }
  header .logo h1{
    margin-top: 2px;
    font-size: 1.7rem;
  }
  
  header button{
    float:right;
    font-size: 15px;
    border-radius:10000px ;
    margin-left: 5px;
    /* margin-top: 5px; */
    font-weight: 600;
    padding: 15px 17px;
    background-color: rgb(30, 35, 48);
    color: white;
    border: none;
  }
  header .login-btn{
    border-radius: 10px;
    background-color: rgb(239, 240, 236);
    color: black;
  }
  .homeContOne{
    /* border: 2px solid red; */
    background-color: rgb(37, 79, 26);
    height: 100vh;
    width: 100vw;
    display: flex;
    
  }
  .title-one{
    /* border:  2px solid blue; */
    height: min-content;
    margin-top: 10rem;
    margin-left: 5rem;
    
  }
  .title-one h1{
    font-size: 60px;
    font-weight: 800;
    text-align: left;
    color: rgb(210, 232, 35);
    letter-spacing: -0.02em;
  }
  .title-one p{
    color: rgb(210, 232, 35);
    font: 20px;
    padding: .5rem;
    font-weight: 600;
  }
  .title-one button{
    /* border: 2px solid red; */
    margin-top: 1rem;
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding: 18px 26px 20px;
    background-color: rgb(233, 192, 233);
    border-radius: 10000px;
  }
  .image-one{
    /* border: 2px solid black; */
    height: fit-content;
    width: fit-content;
    /* display: none; */
    margin-top: 10rem;
    margin-left: 10rem;
  }
  .image-one img{
    height: 50vh;
    width: 30vw;
  }
  
  .homeContTwo{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    background-color: rgb(233, 192, 233);
    /* justify-content: space-between; */
  }
  .image-two{
    /* display: block; */
    /* border: 2px solid red; */
    color:rgb(233, 192, 233) ;
    /* border: none; */
    margin-top: 10rem;
    margin-left: 10rem;

  }
  .image-two img{
    height: 50vh;
    width: 30vw;
  }
  .title-two{
    /* border: 2px solid red; */
    margin-left: 0;
    width: 50vw;
    margin-right: 10vw;
    
  }
  .title-two h1{
    margin: 0 auto;
    color: rgb(80, 34, 116);
    font-size: 50px;
  }
  .title-two p{
    color: rgb(80, 34, 116);
  }
  .title-two button{
    background-color: rgb(80, 34, 116);;
    color: white;
    font-weight: 600;
    padding: 15px 20px;
  }
  .homeContThree{
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: rgb(120, 0, 22);
  }
  .title-three{
    width: 40vw;
  }
  .title-three h1{
    color:rgb(233, 192, 233);
  }
  .title-three p{
    color:rgb(233, 192, 233);
  }

  footer{
    /* border:  2px solid red; */
    padding: 10vh 20vh;
    background-color: #502274;
  }
  .footname{
    /* border: 2px solid red; */
    font-size: 4rem;
    text-align: center;
    padding: 5rem 3rem;
    background-color: #E9C0E9;
    color: #502274;
    font-family: "Link Sans", Arial, sans-serif;
    font-weight: 100;
    border-radius: 30px;
  }

  @media (max-width: 920px){
    footer{
      padding: 4rem 2.5rem;

    }
    .footname{
      padding: 2rem 2px;
      font-size: 1.4rem;
    }
    header{
      width: 70vw;
      gap: 1rem;
    }
    header .logo h1{
      font-size: 1rem;
      margin-top: .7rem;

    }
    header button{
      font-size: 12px;
    
    }
    .homeContOne{
      /* background-color: red; */
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: fit-content;
      gap: 0;
    }
    .image-one{
      margin: 0;
    }
    .image-one img{
      padding: 2rem 0;
      height: 30vh;
      width: 60vw;
      margin: 0;
      margin-bottom: 2rem;
      /* margin-right: 2rem; */
    }
    .title-one{
      margin-left: 2rem;
    }
    .title-one h1{
      font-size: 50px;
    }
    .homeContTwo{
      flex-direction: column;
      align-items: center;
      height: fit-content;
      padding: 2rem 0;
    }
    .image-two img{
      width: 70vw;
      height: 30vh;
    }
    .title-two{
      margin: 0;
      margin-left: 1.5rem;
      width: 90vw;
      padding: 0;
      height: fit-content;
      margin-bottom: 3rem;
    }
    .title-two h1{
      font-size: 30px;
    }
    .title-two p{
      font-size: 14px;
    }
    .homeContThree{
      flex-direction: column;
      align-items: center;
      height: fit-content;
    }
    .image-three img{
      height: 30vh;
      width: 70vw;
    }
    .title-three{
      margin: 0;
      margin-left: 1.5rem;
      margin-top: 5rem;
      width: 90vw;
    }
    .title-three h1{
      font-size: 30px;
    }
    .title-three p{
      font: 14px;
    }
  }

  